<template>

  <div>
    <div class="roster__pdf">
      <b-table-simple
        ref="teamsTable"
        class="position-relative has_padding"
        responsive
        hover
        caption-top
        style="width: 100%; page-break-after: auto; page-break-inside: avoid; border-collapse:separate; border-spacing: 10px 5px;"
      >
        <b-thead head-variant="light">
          <b-tr class="caption_block">
            <th
              :colspan="monthlyGroupingValues.length ? monthlyGroupingValues.length+1 : 1"
              style="text-align: center; background: #4B4B4B !important;border-radius: 4px 4px 0px 0px !important; padding:8px 5px; white-space: no-wrap;"
            >
              <span style="color: #fff;">Monthly Roster {{ selectedMonth }}</span>
            </th>
          </b-tr>
          <b-tr class="group__tag">
            <b-th style="background-color:transparent!important;" />
            <b-th
              v-for="(remark, index) in rosterRemarks"
              :key="index"
              class="group__tag_info text-center"
              :colspan="remark.colspan"
              :style="{ 'background': remark.color + '!important' }"
            >
              <span>{{ remark.message }}</span>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>
              Staff Name
            </b-th>
            <b-th
              v-for="(monthlyGrouping, index) in monthlyGroupingValues"
              :key="index"
              class=""
            >
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'ddd') }} {{ formatLocalMonthlyGroupingDate(monthlyGrouping.monthDate) }}</span>
              <br>
              <span :style="{ 'color': monthlyGrouping.color + '!important' }">{{ formatMonthlyGroupingDate(monthlyGrouping.monthDate, 'D-MMM') }}</span>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(team, key) in teams"
            :key="key"
            style="page-break-after: auto; page-break-inside: avoid;"
          >
            <b-td
              v-if="team.users.length"
              class="no-padding-td"
              style="padding:0!important"
              :colspan="monthlyGroupingValues.length ? monthlyGroupingValues.length + 1 : 1"
            >
              <b-table-simple
                ref="relatedUserTable"
                class="position-relative"
                responsive
                hover
                style="page-break-after: auto; page-break-inside: avoid;"
              >
                <b-thead>
                  <b-tr>
                    <b-th
                      :colspan="monthlyGroupingValues.length ? monthlyGroupingValues.length : 1"
                    >
                      {{ team.name }}
                    </b-th>
                    <b-th class="text-right" />
                  </b-tr>
                </b-thead>
                <b-tbody class="white-body-background">
                  <b-tr
                    v-for="(user, index) in team.users"
                    :key="index"
                  >
                    <b-td style="letter-spacing: 0.5px;">
                      {{ user.name }}
                    </b-td>

                    <b-td
                      v-for="(monthlyGrouping, dateIndex) in monthlyGroupingValues"
                      :key="dateIndex"
                      class="td-no-padding-right hover-td"
                      :style="{ 'background': statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? (`${statusUpdatedData[key][index][dateIndex].data.color} !important`) : '') : '' }"
                    >
                      <div class="">
                        <span>{{ statusUpdatedData[key][index] ? (statusUpdatedData[key][index][dateIndex] ? statusUpdatedData[key][index][dateIndex].data.state : '') : '' }}</span>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <!-- modal -->
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import moment from 'moment'
import 'array.prototype.move'
import Ripple from 'vue-ripple-directive'

let currentUser = ''
let isRest = true

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      annualGroupingValues: [],
      monthlyGroupingValues: [],
      monthlyRemarks: [],
      currentMonth: {},
      selectedMonth: '',
      rosterRemarks: [],
      teams: [],
      statusUpdatedData: [],
      rosterGroups: [],
    }
  },
  mounted() {
    this.$http.get(`download/roster/${this.$route.params.id}/show`)
      .then(response => {
        this.annualGroupingValues = response.data.annualGroupingValues || []
        this.monthlyRemarks = response.data.monthlyRemarks || []
        this.currentMonth = response.data.rosterMonth || {}
        this.rosterGroups = response.data.rosterGroups || []
        this.defineMonthDates(this.currentMonth)
        this.selectedMonth = `${this.currentMonth.name} ${this.currentMonth.rosterYear.name}`
        this.calculateRosterRemarks()
        this.monthlyGroupingStatuses = response.data.monthlyGroupingStatuses || []
        this.teams = response.data.teams || []

        // this.teams.sort((a, b) => this.currentMonth.teamPriority.indexOf(a._id) - this.currentMonth.teamPriority.indexOf(b._id))
        this.dateValuesToPlot()
      })
  },
  methods: {
    defineMonthDates(currentMonth) {
      this.monthlyGroupingValues = []
      const timezone = process.env.VUE_APP_TIME_ZONE
      const currentMoment = moment(`01-${currentMonth.name}-${currentMonth.rosterYear.name}`, 'DD-MMM-YYYY').utcOffset(timezone)
      const endMoment = moment(currentMoment).endOf('month').utcOffset(timezone)
      while (currentMoment.isSameOrBefore(endMoment)) {
        const dateObj = {}

        dateObj.rosterMonth = currentMonth._id
        dateObj.monthDate = currentMoment.format('YYYY-MM-DD')
        dateObj.month = currentMoment.format('MMM')
        dateObj.day = currentMoment.format('ddd')
        this.monthlyGroupingValues.push(dateObj)

        currentMoment.add(1, 'days')
      }
    },
    dateValuesToPlot() {
      this.statusUpdatedData = []
      const totalArray = []
      this.userOptions = []
      this.teams.forEach((element, key) => {
        this.userOptions.push({
          team: element.name,
          name: null,

          _id: element._id,
        })
        const userArray = []
        element.users.forEach((user, index) => {
          const existingUser = this.userOptions.find(o => o._id === user._id)
          if (!existingUser) {
            this.userOptions.push({
              name: user.name,

              _id: user._id,
            })
          }
          const dataArray = []
          this.monthlyGroupingValues.forEach(monthlyGrouping => {
            dataArray.push({ data: this.resolveDateValue(key, index, monthlyGrouping), date: monthlyGrouping.monthDate })
          })
          userArray.push(dataArray)
        })
        totalArray.push(userArray)
      })
      this.statusUpdatedData = totalArray
    },
    resolveDateValue(teamIndex, userIndex, monthlyGrouping) {
      const team = this.teams[teamIndex]
      let user = team.users[userIndex]
      let state = ''
      let color = ''
      let shouldChangeState = true
      user = JSON.parse(JSON.stringify(user))

      // const found = monthlyGrouping.users.find(o => o._id === user._id)
      if (user) {
        const grpStatuses = this.monthlyGroupingStatuses

        const val = grpStatuses.find(o => moment(o.date).isSame(moment(monthlyGrouping.monthDate), 'day') && o.user === user._id)
        if (val) {
          state = val.status.abbreviation
          color = val.status.color
          shouldChangeState = false
        }

        const group = this.annualGroupingValues.find(o => o.user === user._id)
        if (group) {
          const rosterGroup = this.rosterGroups.find(o => o._id === group[moment(monthlyGrouping.monthDate).format('MMM').toLowerCase()])
          if (rosterGroup) {
            const day = moment(monthlyGrouping.monthDate).format('ddd')
            if (rosterGroup.dayOffDays.includes(day)) {
              if (currentUser === '') {
                isRest = false
                if (shouldChangeState) {
                  state = 'OFF'
                  color = '#F5BC16'
                }
              // eslint-disable-next-line no-else-return, no-underscore-dangle
              } else {
                // eslint-disable-next-line no-lonely-if, no-underscore-dangle
                if (currentUser === user._id) {
                  if (isRest === false) {
                    isRest = true
                    if (shouldChangeState) {
                      state = 'REST'
                      color = '#F5BC16'
                    }
                  // eslint-disable-next-line no-else-return
                  } else {
                    isRest = false
                    if (shouldChangeState) {
                      state = 'OFF'
                      color = '#F5BC16'
                    }
                  }
                } else {
                  isRest = false
                  if (shouldChangeState) {
                    state = 'OFF'
                    color = '#F5BC16'
                  }
                }
              }

              currentUser = user._id
            }
          }
        }
      }
      return { state, color }
    },
    calculateRosterRemarks() {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const remarksArray = []
      let includeConsecutiveCount = 1
      let excludeConsecutiveCount = 1
      const remarksDates = []
      this.monthlyRemarks.forEach(value => {
        value.applicableDates.forEach(date => {
          remarksDates.push(moment(date).utcOffset(timezone).format('YYYY-MM-DD'))
        })
      })
      this.monthlyGroupingValues.forEach((obj, key) => {
        if (remarksDates.includes(obj.monthDate)) {
          const foundRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(obj.monthDate), 'day')))
          this.monthlyGroupingValues[key] = { ...this.monthlyGroupingValues[key], color: foundRemark.message ? foundRemark.color : '' }
          if (key < (this.monthlyGroupingValues.length - 1) && remarksDates.includes(this.monthlyGroupingValues[key + 1].monthDate)) {
            const nextRemark = this.monthlyRemarks.find(remark => remark.applicableDates.find(item => moment(item).isSame(moment(this.monthlyGroupingValues[key + 1].monthDate), 'day')))

            if (foundRemark._id === nextRemark._id) {
              includeConsecutiveCount += 1
            } else {
              const thObj = {}
              if (foundRemark.message) {
                thObj.message = foundRemark.message
                thObj.color = foundRemark.color
              } else {
                thObj.message = ''
                thObj.color = ''
              }
              thObj.colspan = includeConsecutiveCount
              remarksArray.push(thObj)

              includeConsecutiveCount = 1
            }
          } else {
            const thObj = {}
            if (foundRemark.message) {
              thObj.message = foundRemark.message
              thObj.color = foundRemark.color
            } else {
              thObj.message = ''
              thObj.color = ''
            }
            thObj.colspan = includeConsecutiveCount
            remarksArray.push(thObj)

            includeConsecutiveCount = 1
          }
        } else {
          this.monthlyGroupingValues[key] = { ...this.monthlyGroupingValues[key], color: '' }
          // eslint-disable-next-line no-lonely-if
          if (key < (this.monthlyGroupingValues.length - 1) && remarksDates.includes(this.monthlyGroupingValues[key + 1].monthDate)) {
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else if (key === (this.monthlyGroupingValues.length - 1)) {
            // excludeConsecutiveCount += 1
            const thObj = {}
            thObj.message = ''
            thObj.color = ''
            thObj.colspan = excludeConsecutiveCount
            remarksArray.push(thObj)

            excludeConsecutiveCount = 1
          } else {
            excludeConsecutiveCount += 1
          }
        }
      })
      this.rosterRemarks = remarksArray
    },
    formatMonthlyGroupingDate(date, format) {
      return moment(date).format(format)
    },
    formatLocalMonthlyGroupingDate(date) {
      return moment(date).locale('zh_cn').format('dd')
    },
  },
}
</script>

<style scoped>
    table td.no-padding-td {
      padding: 12px 0px !important
    }
    table td.td-no-padding-right {
      padding: 12px 8px !important
    }
    .white-body-background {
      background-color: white;
    }
    .v-select {
      width: 95px;
    }
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky;
        top: 38px;
        z-index: 5;
    }
    .b-table-sticky-header > .table.b-table > thead > tr:last-child > th{
      position: sticky;
        top: 103px;
        z-index: 5;
    }
    .roster__grouping.monthly__grouping .table.b-table.b-table-caption-top > caption {
        position: sticky;
        top: 0;
        z-index: 5;
    }
    .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column{
      left:0;
      z-index: 6!important;
    }
    .roster__grouping.monthly__grouping .no-padding-td .table-responsive > table > tbody > tr > td.b-table-sticky-column{
      left:0;
      z-index: 3!important;
      position: sticky;
    }
</style>
<style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
</style>
<style>
    @media print {
        html, body {
            height: 99%;
        }
    }
</style>
